import React from 'react'
import {Modal} from 'antd';

const QRModal = ({isModalOpen, setIsModalOpen}) => {
    return (
        <>
            <Modal open={isModalOpen}
                   className="ant-modal--qr"
                   centered={true}
                   onCancel={() => {setIsModalOpen(false)}}
                   closeIcon={<i className='icon-close'></i>}
                   footer={false}>
                <h2 className='text--h4 text--dark'>Papel Uygulamasını İndir</h2>
                <span className='text--p text--dark'> İndirmek için QR kodunu tara</span>
                <figure>
                    <img src='images/store-qr.png' alt='qr code example'/>
                </figure>
                <span className='text--p text--dark'>ya da uygulama marketlerini ziyaret et.</span>
            </Modal>


        </>
    )
}

export default QRModal