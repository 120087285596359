import { Row, Col } from "antd";
import { motion } from "framer-motion";
import { Scene } from "react-scrollmagic";
import { Timeline, Tween } from "react-gsap";
import { PriceBox, ProductBox } from "../../index";
import {
  posCardVariants,
  phoneNotificationVariants,
  phoneTextEvenVariants,
  rotateXVariants,
  topToBottomVariants,
  rotateXVariantsBottom,
} from "../../../utilities/Config";
import Accordion from "../../Accordion";
const SectionBusiness = () => {
  const accordionData = [
    {
      title: "Gönder & Al",
      text: "Kolay ve güvenli bir şekilde para gönderip ve alabilirsiniz",
      child: [
        <Col className="image--send-receive" md={12}>
          <motion.figure
            className="accessory-card"
            variants={topToBottomVariants}
            initial="hidden"
            whileInView="whileInView"
            viewport={{ once: true, amount: 0.1 }}
          >
            <img
              src="images/papel-card.png"
              srcSet="images/papel-card-2x.png 2x, images/papel-card.png 1x"
              alt="Papel Card"
            />
          </motion.figure>
          <motion.div
            className="accessory-1"
            variants={phoneTextEvenVariants}
            initial="hidden"
            whileInView="whileInView"
            viewport={{ once: true, amount: 0.1 }}
          >
            <PriceBox type="green" icon="shopping-bag-line" text="Shopping" />
            <PriceBox />
            <PriceBox
              type="pink"
              icon="t-shirt-line"
              text="Clothes"
              price="$ 120,61"
            />
          </motion.div>
        </Col>,
      ],
    },
    {
      title: "Ödemeler",
      text: "Fatura ve masraf ödemelerini hızlı ve güvenli şekilde gerçekleştir.",
      child: [
        <Col className="image" md={12}>
          <motion.figure
            className="papel-pos-card"
            variants={rotateXVariants}
            initial="hidden"
            whileInView="whileInView"
            viewport={{ once: true, amount: 0.1 }}
          >
            <img
              src="images/papel-card.png"
              srcSet="images/papel-pos-card-2x.png 2x, images/papel-pos-card.png 1x"
              alt="Papel Card"
            />
          </motion.figure>
          <motion.figure
            className="pos"
            variants={phoneTextEvenVariants}
            initial="hidden"
            whileInView="whileInView"
            viewport={{ once: true, amount: 0.1 }}
          >
            <img
              src="images/papel-card.png"
              srcSet="images/pos.png 2x, images/pos.png 1x"
              alt="Pos"
            />
          </motion.figure>
          <motion.figure
            className="pos-card-color"
            variants={rotateXVariantsBottom}
            initial="hidden"
            whileInView="whileInView"
            viewport={{ once: true, amount: 0.1 }}
          >
            <img
              src="images/papel-card.png"
              srcSet="images/pos-card-color.png 2x, images/pos-card-color.png 1x"
              alt="Papel Card"
            />
          </motion.figure>
        </Col>,
      ],
    },
    {
      title: "Ekipler",
      text: "Ekip harcamalarını yönetmek, faturaları takip etmek, bölüştürmek güvenli ve verimli bir hale geliyor.",
      child: [
        <motion.Col
          variants={phoneNotificationVariants}
          initial="hidden"
          viewport={{ once: true, amount: 0.1 }}
          whileInView="whileInView"
          className="image--teams"
          md={12}
        >
          <motion.figure className="teams-1" variants={posCardVariants}>
            <img
              src="images/teams-1.png"
              srcSet="images/teams-1.png 2x, images/teams-1.png 1x"
              alt="Papel Card"
            />
          </motion.figure>
          <motion.figure className="teams-2" variants={posCardVariants}>
            <img
              src="images/teams-2.png"
              srcSet="images/teams-2.png 2x, images/teams-2.png 1x"
              alt="Pos"
            />
          </motion.figure>
          <motion.figure className="teams-3" variants={posCardVariants}>
            <img
              src="images/teams-3.png"
              srcSet="images/teams-3-2x.png 2x, images/teams-3.png 1x"
              alt="Papel Card"
            />
          </motion.figure>
        </motion.Col>,
      ],
    },
    {
      title: "Müşteriler",
      text: "Müşterileriniz için faturaları ve ödemeleri zahmetsiz bir hale getirin.",
      child: [
        <Col className="image image--customer" md={12}>
          <motion.figure
            className="customer-1"
            variants={rotateXVariants}
            initial="hidden"
            whileInView="whileInView"
            viewport={{ once: true, amount: 0.1 }}
          >
            <img
              src="images/map.png"
              srcSet="images/map.png 2x, images/map.png 1x"
              alt="Papel Card"
            />
          </motion.figure>
          <motion.figure
            className="customer-2"
            variants={phoneTextEvenVariants}
            initial="hidden"
            whileInView="whileInView"
            viewport={{ once: true, amount: 0.1 }}
          >
            <img
              src="images/customer-pin.png"
              srcSet="images/customer-pin.png 2x, images/customer-pin.png 1x"
              alt="Pos"
            />
          </motion.figure>
          <motion.figure
            className="customer-3"
            variants={rotateXVariantsBottom}
            initial="hidden"
            whileInView="whileInView"
            viewport={{ once: true, amount: 0.1 }}
          >
            <img
              src="images/customer.png"
              srcSet="images/customer.png 2x, images/customer.png 1x"
              alt="Papel Card"
            />
          </motion.figure>
          <motion.figure
            className="customer-4"
            variants={rotateXVariantsBottom}
            initial="hidden"
            whileInView="whileInView"
            viewport={{ once: true, amount: 0.1 }}
          >
            <img
              src="images/customer-message.png"
              srcSet="images/customer-message.png 2x, images/customer-message.png 1x"
              alt="Papel Card"
            />
          </motion.figure>
        </Col>,
      ],
    },
  ];

  return (
    <section className="section-business">
      <div className="container">
        <Scene
          triggerHook="onLeaveBack"
          classToggle="scroll-magic"
          duration={5000}
          pin
        >
          {(progress) => (
            <div className="sticky">
              <Timeline
                wrapper={<div className="pinContainer" />}
                totalProgress={progress}
                paused
              >
                <Tween>
                  <div className="test">
                    <div>
                      <Row
                        className="section-business__text text--white"
                        gutter={24}
                      >
                        <Col md={10} span={24}>
                          <Timeline
                            target={
                              <h2 className="text--h1 text--white">
                                işletmeniz
                                <span className="text--gradient-v3"> için</span>
                              </h2>
                            }
                          >
                            <Tween
                              from={{ opacity: 0, x: "-200px", y: "600px" }}
                              to={{ opacity: 1, x: "0", y: "0" }}
                            />
                          </Timeline>
                        </Col>
                        <Col md={{ span: 10, offset: 4 }} span={24}>
                          <Timeline
                            target={
                              <p className="text--subtitle text--white">
Ödemeleriniz ile beraber paranızı güvenli, basit ve kolay yönetmenin yolunu sunmaktan mutluluk duyuyoruz. Herkes için sınır ötesi ödemelere imkan sağlayarak, uçtan uca ödeme çözümleri sunuyoruz.
                              </p>
                            }
                          >
                            <Tween
                              from={{ opacity: 0, x: "600px" }}
                              to={{ opacity: 1, x: "20px" }}
                            />
                          </Timeline>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tween>
              </Timeline>
            </div>
          )}
        </Scene>

        <Accordion data={accordionData} tabPosition="right" />

        <Scene
          triggerHook="onLeaveBack"
          classToggle="scroll-magic"
          duration={5000}
          pin
        >
          {(progress) => (
            <div className="sticky">
              <Timeline
                wrapper={<div className="pinContainer" />}
                totalProgress={progress}
                paused
              >
                <Tween>
                  <div className="test">
                    <div>
                      <Row className="section-business__text" gutter={24}>
                        <Col md={12} span={24}>
                          <Timeline
                            target={
                              <h2 className="text--h3 text--dark">
                                İşletmenizi Papel ile dönüştürün
                              </h2>
                            }
                          >
                            <Tween
                              from={{ opacity: 0, x: "-200px", y: "600px" }}
                              to={{ opacity: 1, x: "0", y: "0" }}
                            />
                          </Timeline>
                        </Col>
                        <Col md={{ span: 8, offset: 4 }} span={24}>
                          <Timeline
                            target={
                              <p className="text--caption text--dark">
                                Tüm finansal işlemlerinizi tek yerde yönetin; harcama, tasarruf, yatırım ve daha fazlası. Kolaylık ve konfor için seçiminizi yapın.
                              </p>
                            }
                          >
                            <Tween
                              from={{ opacity: 0, x: "600px" }}
                              to={{ opacity: 1, x: "20px" }}
                            />
                          </Timeline>
                        </Col>
                      </Row>
                      <Row
                        className="section-business__products"
                        gutter={[35, 24]}
                      >
                        <Col md={12} span={24}>
                          <Timeline>
                            <Tween
                              from={{ opacity: 0, y: "100px" }}
                              to={{ opacity: 1, y: "0" }}
                            >
                              <div>
                                <ProductBox
                                  className="product--payment"
                                  boxType="dark"
                                  image={
                                    <figure>
                                      <img
                                        src="images/product-payment.png"
                                        srcSet="images/product-payment-2x.png 2x, images/product-payment.png 1x"
                                        alt="product payment"
                                      />
                                    </figure>
                                  }
                                />
                              </div>
                            </Tween>
                          </Timeline>
                        </Col>
                        <Col md={12} span={24}>
                          <Timeline>
                            <Tween
                              from={{ opacity: 0, y: "100px" }}
                              to={{ opacity: 1, y: "0" }}
                            >
                              <div>
                                <ProductBox
                                  className="product--desktop"
                                  title="Masaüstü"
                                  icons={["bank-card", "qr-code"]}
                                  image={
                                    <figure>
                                      <img
                                        src="images/product-desktop.png"
                                        srcSet="images/product-desktop-2x.png 2x, images/product-desktop.png 1x"
                                        alt="product desktop"
                                      />
                                    </figure>
                                  }
                                />
                              </div>
                            </Tween>
                          </Timeline>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tween>
              </Timeline>
            </div>
          )}
        </Scene>
        <Scene
          triggerHook="onLeave"
          classToggle="scroll-magic"
          duration={5000}
          pin
        >
          {(progress) => (
            <div className="sticky">
              <Timeline
                wrapper={<div className="pinContainer2" />}
                totalProgress={progress}
                paused
              >
                <Tween>
                  <div className="test">
                    <div>
                      <Row
                        className="section-business__products"
                        gutter={[35, 24]}
                      >
                        <Col span={24}>
                          <Timeline>
                            <Tween
                              from={{ opacity: 0, y: "400px" }}
                              to={{ opacity: 1, y: "20px" }}
                            >
                              <div>
                                <ProductBox
                                  className="product--terminal"
                                  horizontal
                                  boxType="pink"
                                  type="Papel"
                                  title="Mobil Terminal"
                                  image={
                                    <figure>
                                      <img
                                        src="images/product-terminals.png"
                                        srcSet="images/product-terminals-2x.png 2x, images/product-terminals.png 1x"
                                        alt="product terminals"
                                      />
                                    </figure>
                                  }
                                />
                              </div>
                            </Tween>
                          </Timeline>
                        </Col>
                      </Row>
                      <Row className="section-business__text" gutter={24}>
                        <Col className="" md={10} span={24}>
                          <Timeline
                            target={
                              <h2 className="text--h3 text--dark">
                                İşletmeniz için hepsi <span className="text--gradient-v4">
                                   bir arada
                                </span>
                              </h2>
                            }
                          >
                            <Tween
                              from={{ opacity: 0, x: "-200px" }}
                              to={{ opacity: 1, x: "0" }}
                            />
                          </Timeline>
                        </Col>
                        <Col
                          className="grid-right"
                          md={{ span: 12, offset: 2 }}
                          span={24}
                        >
                          <Timeline
                            target={
                              <p className="text--h4 text--dark">
Güvenliğin önemini biliyoruz. Uygulamalarımızı kullandığınızda kişisel bilgilerinizi ve mali durumunuzu korumaya öncelik veriyoruz. Güvenliğinizin bizim en büyük önceliğimizdir.
                              </p>
                            }
                          >
                            <Tween
                              from={{ opacity: 0, x: "200px" }}
                              to={{ opacity: 1, x: "0" }}
                            />
                          </Timeline>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tween>
              </Timeline>
            </div>
          )}
        </Scene>

        <Scene
          triggerHook="onLeaveBack"
          classToggle="scroll-magic"
          duration={1500}
          pin
        >
          {(progress) => (
            <div>
              <Timeline
                wrapper={<div className="pinContainer2" />}
                totalProgress={progress}
                paused
              >
                <Tween>
                  <div className="test">
                    <div>
                      <Row>
                        <Col span={24}>
                          <div className="accessory-dashboard-div">
                            <Timeline>
                              <Tween
                                from={{
                                  rotationX: 60,
                                  y: "-240px",
                                }}
                                to={{ rotationX: 0 }}
                              >
                                <figure className="accessory-dashboard">
                                  <img
                                    src="images/papel-dashboard.png"
                                    srcSet="images/papel-dashboard-2x.png 2x, images/papel-dashboard.png 1x"
                                    alt="papel dashboard"
                                  />
                                </figure>
                              </Tween>
                            </Timeline>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tween>
              </Timeline>
            </div>
          )}
        </Scene>
      </div>
    </section>
  );
};

export default SectionBusiness;
