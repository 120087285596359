const Questions = [
    {
        title: "“Papel” nedir ?",
        content: <p className="text--caption">Papel, dijital cüzdan altyapısı, kartlı veya kartsız ödeme, banka transferi ve diğer alternatif ödeme yöntemlerini tek bir çatı altında birleştiren bir ödeme sistemidir. Papel, 6493 sayılı kanun kapsamında elektronik para ihracı lisansını almıştır. Tüm Ödeme Kuruluşları ve Elektronik Para Şirketleri gibi Merkez Bankası tarafından denetlenen finansal teknolojiler endüstrisinde faaliyet göstermektedir.</p>,
        key: 'question-1'
    },
    {
        title: "Papel'i kimler kullanabilir?",
        content: <p className="text--caption">13 yaşından büyük herkes Papel dijital cüzdanını kullanabilir. 18 yaşından büyük gerçek ve tüzel kişiler ödeme hizmetlerimizden faydalanabilirler.</p>,
        key: 'question-2'
    },
    {
        title: "Papel'i kullanmak ücretli mi?",
        content: <p className="text--caption">Papel'e ücretsiz kayıt olabilirsiniz. Kayıt ücreti, aylık veya yıllık ücretimiz yok.</p>,
        key: 'question-3'
    },
    {
        title: "Papel'de hangi banka kartlarını kullanabilirim?",
        content: <p className="text--caption">Tüm banka kartlarıyla işlem yapabilirsiniz.</p>,
        key: 'question-4'
    },
]
const MenuLinks = [
    {
        title: 'BİREYSEL',
        href: '/bireysel'
    },
    {
        title: 'TİCARİ',
        href: '/ticari'
    },
    {
        title: 'GOLD KART',
        href: '/gold-kart'
    },
    {
        title: 'KARTLAR',
        href: '/kartlar'
    },
    // {
    //     title: 'FEES',
    //     href: '/fees-limits'
    // },
    // {
    //     title: 'SECURITY',
    //     href: '/security'
    // },
    // {
    //     title: 'HELP',
    //     href: '/help'
    // },
]

const HelpBoxes = [
    {
        image: 'images/box-payment.png',
        title: 'Payment'
    },
    {
        image: 'images/box-papel-card.png',
        title: 'Papel Card'
    },
    {
        image: 'images/box-cashback.png',
        title: 'Cashback'
    },
    {
        image: 'images/box-account.png',
        title: 'Account'
    },
    {
        image: 'images/box-transfer.png',
        title: 'Transfer'
    },
    {
        image: 'images/box-papel-card.png',
        title: 'Business'
    }
]

const TeamMembers = [
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
    {
        image: 'images/profile-1.png',
        name: 'Cristofer Rhiel Madsen',
        title: 'Co-Founder and CEO'
    },
]


export { Questions, MenuLinks, HelpBoxes, TeamMembers }