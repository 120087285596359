import React from 'react';
import { Route, Routes, BrowserRouter as Router} from "react-router-dom";
import {LandingPage, FeesLimitsPage, HelpPage, SecurityPage , PoliciesPage, BusinessPage, PersonalPage, CardsPage, GoldCardsPage} from "../pages";
import ScrollToTop from "../utilities/Functions/scrollToTop";
const RoutesComp = () => {
    //const path = '/papel/build';
    const path = '';

    const routes = [
        {
            path: `${path}`,
            exact: true,
            component: <LandingPage />
        },
        {
            path: `${path}/fees-limits`,
            exact: true,
            component: <FeesLimitsPage />
        },
        {

            path: `${path}/security`,
            exact: true,
            component: <SecurityPage />
        },
        {
            path: `${path}/help`,
            exact: true,
            component: <HelpPage />
        },
        /*{
            path: `${path}/about`,
            exact: true,
            component: <AboutPage />
        },*/
        {
            path: `${path}/policies`,
            exact: true,
            component: <PoliciesPage />,
        },
        /*{
            path: `${path}/contact`,
            exact: true,
            component: <ContactPage />,
        },*/
        {
            path: `${path}/bireysel`,
            exact: true,
            component: <PersonalPage />,
        },
        {
            path: `${path}/ticari`,
            exact: false,
            component: <BusinessPage />,
        },
        {
            path: `${path}/kartlar`,
            exact: true,
            component: <CardsPage />,
        },
        {
            path: `${path}/gold-kart`,
            exact: true,
            component: <GoldCardsPage />,
        },
    ]
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <>
                    { routes.map(({ path, component, index, exact }) => {
                        return <Route  path={path} key={'route-' + index} element={component}/>;
                    })}
                </>
            </Routes>
        </Router>
    )
}

export default RoutesComp;