import React, {useState, forwardRef, useRef} from "react";

import {Row, Col} from "antd";
import {Button, Checkbox} from "../../index";
import "./temporary-form.scss";
import AgreementModal from "../../Modals/AgreementModal";
import {useFormik} from "formik";
import {validationSchema} from "./validationSchema";

import RecaptchaModal from "../../Modals/RecaptchaModal";
import {Controller, Scene} from "react-scrollmagic";
import {Timeline, Tween} from "react-gsap";
import {rotateYVariants} from "../../../utilities/Config";


const SectionContact = forwardRef(() => {
    const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
    const [isRecaptchaModalOpen, setIsRecaptchaModalOpen] = useState(false);
    const [currentForm, setCurrentForm] = useState(null);
    const form = useRef();


    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            interested: "",
            phone_number: "",
            checked: false,
        },
        validationSchema: validationSchema,

        onSubmit: (values, {resetForm}) => {
            setCurrentForm({
                form: form.current,
                resetFonk: resetForm
            })
            setIsRecaptchaModalOpen(true)

        },
    });
    return (
        <section id="section-contact" className="section-contact">
            <div className="container">
                    <Scene
                        triggerHook="onLeave"
                        classToggle="scroll-magic"
                        duration={5000}
                        pin
                    >
                        {progress => (
                            <div className="sticky">
                                <Timeline wrapper={<div className="pinContainer2"/>} totalProgress={progress} paused>
                                    <Tween>
                                        <div className="test">
                                            <div>
                                                <Row gutter={24}>
                                                    <Col lg={8} span={24}>
                                                        <Timeline>
                                                            <Tween
                                                                from={{opacity: 0, x: '-200px', y: '100px'}}
                                                                to={{opacity: 1, x: '0', y: '100px'}}
                                                            >
                                                                <h1 className="text--h3 text--white">Papel ile hemen satışa başlayın</h1>
                                                            </Tween>
                                                        </Timeline>
                                                        <Timeline>
                                                            <Tween
                                                                from={{opacity: 0, x: '200px', y: '100px'}}
                                                                to={{opacity: 1, x: '0', y: '100px'}}
                                                            ><span className="text--caption">
Diğer işletme sahiplerinden tavsiyeler, makalelere erişim, sektör uzmanlarından ipuçları ve daha fazlasını almak için e-posta listemize abone olun.
                                                            </span>
                                                            </Tween>
                                                        </Timeline>
                                                    </Col>
                                                    <Col lg={{span: 12, offset: 4}} span={24}>
                                                        {/* <Form className="form"
                              layout="vertical"
                              form={contactForm}
                              onFinish={sendEmail}
                            //   ref={form}
                              >
                            
                            <div>
                                <h6 className="form__title text--h6">Say Hello</h6>
                                <Form.Item label="Name"
                                           name="name"
                                           rules={[{ required: true, message: "Enter a name!" }]}>
                                    <Input placeholder="Name Surname" />
                                </Form.Item>
                                <Form.Item
                                    label="Email Address"
                                    name="email"
                                    rules={[{ required: true, message: "Enter a valid email!" }]}
                                >
                                    <Input placeholder="hi@example.com"/>
                                </Form.Item>
                                <Form.Item
                                    label="Interested in"
                                    name="interested"
                                    rules={[{ required: true, message: "Enter a interested!" }]}
                                >
                                    <Input placeholder="Papel Merchant" />
                                </Form.Item>
                                <Form.Item
                                    label="Phone Number"
                                    name="phone_number"
                                    rules={[{ required: true, message: "Enter a phone number!" }]}
                                >
                                    <Input placeholder="(500) 000 00 00" />
                                </Form.Item>
                                <div className="form__action">
                                    <Form.Item style={{ marginBottom: 0 }} className="agreement-group">
                                        <Checkbox text={<span className="checkbox__text text--caption"><span onClick={()=>{setIsModalOpen(true)}} className="agreement-btn">User Agreement</span>, I accept.</span>}/> 
                                        
                                    </Form.Item>
                                    <Button type="primary" iconRight={<i className="icon-arrow-right-line"></i>}>Send</Button>
                                </div>
                            </div>
                        </Form> */}
                                                        <Timeline>
                                                            <Tween
                                                                from={{opacity: 0, rotationY: 90, y: '100px'}}
                                                                to={{opacity: 1, rotationY: 0, y: '20px'}}
                                                            >
                                                                <div className="rotatey">
                                                                    <div>
                                                                        <form className="form" ref={form}
                                                                              onSubmit={formik.handleSubmit}>
                                                                            <div className="form__div">
                                                                                <h6 className="form__title text--h6">İşinizi büyütmeye başlayın!</h6>
                                                                                <div className="form__div-group">
                                                                                    <label htmlFor="name">Ad</label>
                                                                                    <input
                                                                                        name="name"
                                                                                        value={formik.values.name}
                                                                                        onChange={formik.handleChange}
                                                                                        placeholder="Ad Soyad"
                                                                                    />
                                                                                    {formik.errors.name && formik.touched.name && (
                                                                                        <p className="error">{formik.errors.name}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="form__div-group">
                                                                                    <label htmlFor="email">Mail Adresi</label>
                                                                                    <input
                                                                                        type="email"
                                                                                        value={formik.values.email}
                                                                                        onChange={formik.handleChange}
                                                                                        name="email"
                                                                                        placeholder="merhaba@papel.com"
                                                                                    />
                                                                                    {formik.errors.email && formik.touched.email && (
                                                                                        <p className="error">{formik.errors.email}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="form__div-group">
                                                                                    <label htmlFor="interested">İlgilendiğiniz Ürün
                                                                                        </label>
                                                                                    <input
                                                                                        name="interested"
                                                                                        value={formik.values.interested}
                                                                                        onChange={formik.handleChange}
                                                                                        placeholder="Papel Üye İşyeri"
                                                                                    />
                                                                                    {formik.errors.interested && formik.touched.interested && (
                                                                                        <p className="error">{formik.errors.interested}</p>
                                                                                    )}
                                                                                </div>
                                                                                <div className="form__div-group">
                                                                                    <label htmlFor="phone_number">Telefon Numarası</label>
                                                                                    <input
                                                                                        name="phone_number"
                                                                                        type="tel"
                                                                                        pattern="\d*"
                                                                                        value={formik.values.phone_number}
                                                                                        onChange={formik.handleChange}
                                                                                        placeholder="Telefon Numarası"
                                                                                    />
                                                                                    {formik.errors.phone_number && formik.touched.phone_number && (
                                                                                        <p className="error">{formik.errors.phone_number}</p>
                                                                                    )}
                                                                                </div>


                                                                                <div className="form__btn-group">
                                                                                    <Checkbox
                                                                                        checked={formik.values.checked}
                                                                                        name="checked"
                                                                                        value={formik.values.checked}
                                                                                        onChange={formik.handleChange}
                                                                                        text={
                                                                                            <span
                                                                                                className="checkbox__text text--caption">
                                                        <span onClick={() => {
                                                            setIsAgreementModalOpen(true);
                                                        }}
                                                              className="agreement-btn">Kullanıcı Sözleşmesi'ni</span>, Okudum ve onaylıyorum.</span>
                                                                                        }
                                                                                    />
                                                                                    {/* <ReCAPTCHA
            className="recaptcha"
            value={formik.values.recaptcha}
            size="invisible"
            name="recaptcha"
            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
            onChange={async () => {
              const token = await reCaptchaRef.current.execute();
              formik.setFieldValue("recaptcha", token);
            }}
            ref={reCaptchaRef}
          /> */}
                                                                                    {formik.errors.checked && formik.touched.checked &&
                                                                                        <p className="error">{formik.errors.checked}</p>}


                                                                                    <Button
                                                                                        btnType="submit"
                                                                                        type="primary"
                                                                                        iconRight={<i
                                                                                            className="icon-arrow-right-line"></i>}
                                                                                    >
                                                                                        Gönder
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </Tween>
                                                        </Timeline>
                                                    </Col>
                                                </Row>
                                                <Timeline>
                                                    <Tween
                                                        from={{x: '-1500px', y: '100px'}}
                                                        to={{x: '0', y: '100px'}}
                                                    >
                                                        <div className="section-contact__accessory">
                                                            <span
                                                                className="text--lg text--gradient-stroke">iletişim</span>
                                                        </div>
                                                    </Tween>
                                                </Timeline>

                                            </div>
                                        </div>
                                    </Tween>
                                </Timeline>
                            </div>
                        )}
                    </Scene>
            </div>
            <AgreementModal
                isModalOpen={isAgreementModalOpen}
                setIsModalOpen={setIsAgreementModalOpen}
            />
            <RecaptchaModal
                isModalOpen={isRecaptchaModalOpen}
                setIsModalOpen={setIsRecaptchaModalOpen}
                currentForm={currentForm}
            />

        </section>
    );
});

export default SectionContact;
