import { Col, Row } from 'antd'
import { motion } from "framer-motion";
import VerticalTextScroll from '../../../utilities/Functions/verticalTextScroll'
import { phoneTextVariants, textVariants, productBoxContainerVariants, phoneTextEvenVariants } from "../../../utilities/Config";

const Flights = () => {
    const verticalTextAnimation = VerticalTextScroll();
    return (
        <section className="section-flights">
            <div className="container">
                <motion.div variants={productBoxContainerVariants}
                    initial="hidden"
                    viewport={{ once: true, amount: .1 }}
                    whileInView="whileInView" className="section-flights__head">
                    <motion.span variants={phoneTextVariants} className="text--subtitle text-center">Sizin için </motion.span>
                    <motion.span variants={phoneTextVariants} className="text--h3 text-center">özel uçuş planlayabilirim</motion.span>
                    <motion.figure variants={textVariants} initial="hidden" animate="visible">
                        <img src="images/flights.png" srcSet='images/flights-2x.png 2x, images/flights.png 1x' alt="Flights" />
                    </motion.figure>
                </motion.div>
                <motion.div className="accessory-1" style={{ x: verticalTextAnimation }}>
                    <span className="text--xl text--gradient-stroke">uçuş</span>
                    <figure>
                        <img src="images/accessory-plane.png" srcSet='images/accessory-plane-2x.png 2x, images/accessory-plane.png 1x' alt="uçuş" />
                    </figure>
                    <span className="text--xl text--gradient-dark-stroke">otel</span>
                    <figure>
                        <img src="images/accessory-dinner.png" srcSet='images/accessory-dinner-2x.png images/accessory-dinner.png 1x' alt="Dinner" />
                    </figure>
                    <span className="text--xl text--gradient-dark-stroke">rezervasyon</span>
                    <figure>
                        <img src="images/accessory-dinner.png" srcSet='images/accessory-dinner-2x.png images/accessory-dinner.png 1x' alt="Dinner" />
                    </figure>
                    <span className="text--xl text--gradient-stroke">uçuş</span>
                    <figure>
                        <img src="images/accessory-plane.png" srcSet='images/accessory-plane-2x.png 2x, images/accessory-plane.png 1x' alt="uçuş" />
                    </figure>
                    <span className="text--xl text--gradient-dark-stroke">otel</span>
                    <figure>
                        <img src="images/accessory-dinner.png" srcSet='images/accessory-dinner-2x.png images/accessory-dinner.png 1x' alt="Dinner" />
                    </figure>
                    <span className="text--xl text--gradient-dark-stroke">rezervasyon</span>
                    <figure>
                        <img src="images/accessory-dinner.png" srcSet='images/accessory-dinner-2x.png images/accessory-dinner.png 1x' alt="Dinner" />
                    </figure>
                    <span className="text--xl text--gradient-stroke">uçuş</span>
                    <figure>
                        <img src="images/accessory-plane.png" srcSet='images/accessory-plane-2x.png 2x, images/accessory-plane.png 1x' alt="uçuş" />
                    </figure>
                    <span className="text--xl text--gradient-dark-stroke">otel</span>
                    <figure>
                        <img src="images/accessory-dinner.png" srcSet='images/accessory-dinner-2x.png images/accessory-dinner.png 1x' alt="Dinner" />
                    </figure>
                    <span className="text--xl text--gradient-dark-stroke">rezervasyon</span>
                    <figure>
                        <img src="images/accessory-dinner.png" srcSet='images/accessory-dinner-2x.png images/accessory-dinner.png 1x' alt="Dinner" />
                    </figure>
                    <span className="text--xl text--gradient-stroke">uçuş</span>
                    <figure>
                        <img src="images/accessory-plane.png" srcSet='images/accessory-plane-2x.png 2x, images/accessory-plane.png 1x' alt="uçuş" />
                    </figure>
                    <span className="text--xl text--gradient-dark-stroke">otel</span>
                    <figure>
                        <img src="images/accessory-dinner.png" srcSet='images/accessory-dinner-2x.png images/accessory-dinner.png 1x' alt="Dinner" />
                    </figure>
                    <span className="text--xl text--gradient-dark-stroke">rezervasyon</span>
                </motion.div>
                <motion.div variants={productBoxContainerVariants}
                    initial="hidden"
                    viewport={{ once: true, amount: .1 }}
                    whileInView="whileInView">
                    <Row gutter={24} className="odd">
                        <Col sm={12} span={24}>
                            <motion.figure variants={textVariants} initial="hidden" animate="visible">
                                <img src="images/hotel.png" srcSet='images/hotel-2x.png 2x, images/hotel.png 1x' alt="Hotel" />
                            </motion.figure>
                        </Col>
                        <Col sm={12} span={24}>
                            <motion.span variants={phoneTextEvenVariants} className="text--subtitle">Sizin için</motion.span>
                            <motion.span variants={phoneTextEvenVariants} className="text--h3">Otel rezervasyonu yapabilirim.</motion.span>
                        </Col>
                    </Row>
                </motion.div>
                <motion.div variants={productBoxContainerVariants}
                    initial="hidden"
                    viewport={{ once: true, amount: .1 }}
                    whileInView="whileInView">
                    <Row gutter={24} className="even">
                        <motion.div className="accessory-2" style={{ x: verticalTextAnimation }}>
                            <span className="text--xl text--gradient-stroke">uçuş</span>
                            <figure>
                                <img src="images/accessory-plane.png" srcSet='images/accessory-plane-2x.png 2x, images/accessory-plane.png 1x' alt="uçuş" />
                            </figure>
                            <span className="text--xl text--gradient-dark-stroke">otel</span>
                            <figure>
                                <img src="images/accessory-dinner.png" srcSet='images/accessory-dinner-2x.png images/accessory-dinner.png 1x' alt="Dinner" />
                            </figure>
                            <span className="text--xl text--gradient-dark-stroke">rezervasyon</span>
                            <figure>
                                <img src="images/accessory-dinner.png" srcSet='images/accessory-dinner-2x.png images/accessory-dinner.png 1x' alt="Dinner" />
                            </figure>
                            <span className="text--xl text--gradient-stroke">uçuş</span>
                            <figure>
                                <img src="images/accessory-plane.png" srcSet='images/accessory-plane-2x.png 2x, images/accessory-plane.png 1x' alt="uçuş" />
                            </figure>
                            <span className="text--xl text--gradient-dark-stroke">otel</span>
                            <figure>
                                <img src="images/accessory-dinner.png" srcSet='images/accessory-dinner-2x.png images/accessory-dinner.png 1x' alt="Dinner" />
                            </figure>
                            <span className="text--xl text--gradient-dark-stroke">rezervasyon</span>
                            <figure>
                                <img src="images/accessory-dinner.png" srcSet='images/accessory-dinner-2x.png images/accessory-dinner.png 1x' alt="Dinner" />
                            </figure>
                            <span className="text--xl text--gradient-stroke">uçuş</span>
                            <figure>
                                <img src="images/accessory-plane.png" srcSet='images/accessory-plane-2x.png 2x, images/accessory-plane.png 1x' alt="uçuş" />
                            </figure>
                            <span className="text--xl text--gradient-dark-stroke">otel</span>
                            <figure>
                                <img src="images/accessory-dinner.png" srcSet='images/accessory-dinner-2x.png images/accessory-dinner.png 1x' alt="Dinner" />
                            </figure>
                            <span className="text--xl text--gradient-dark-stroke">rezervasyon</span>
                            <figure>
                                <img src="images/accessory-dinner.png" srcSet='images/accessory-dinner-2x.png images/accessory-dinner.png 1x' alt="Dinner" />
                            </figure>
                            <span className="text--xl text--gradient-stroke">uçuş</span>
                            <figure>
                                <img src="images/accessory-plane.png" srcSet='images/accessory-plane-2x.png 2x, images/accessory-plane.png 1x' alt="uçuş" />
                            </figure>
                            <span className="text--xl text--gradient-dark-stroke">otel</span>
                            <figure>
                                <img src="images/accessory-dinner.png" srcSet='images/accessory-dinner-2x.png images/accessory-dinner.png 1x' alt="Dinner" />
                            </figure>
                            <span className="text--xl text--gradient-dark-stroke">rezervasyon</span>
                        </motion.div>
                        <Col sm={12} span={24}>
                            <motion.span variants={phoneTextVariants} className="text--subtitle">İstediğiniz restoranda sizin için</motion.span>
                            <motion.span variants={phoneTextVariants} className="text--h3">Rezervasyon yapabilirim.</motion.span>
                        </Col>
                        <Col sm={12} span={24}>
                            <motion.figure variants={textVariants} initial="hidden" animate="visible">
                                <img src="images/restaurant.png" srcSet='images/restaurant-2x.png 2x, images/restaurant.png 1x' alt="Restaurant" />
                            </motion.figure>
                        </Col>
                    </Row>
                </motion.div>
            </div>
        </section>
    )
}

export default Flights