import { useEffect, useRef } from "react";
import { Controller, Scene } from "react-scrollmagic";
import { Timeline, Tween } from "react-gsap";
import {
  motion,
  useScroll,
  useTransform,
} from "framer-motion";
import {
  Header,
  Button,
  ChatMessage,
  MessageBox,
  IconCircle,
  Triangle,
  UserPortrait,
  ActionMenu,
  SectionBusiness,
  SectionLoyalty,
  SectionFinancial,
  SectionContact,
  SectionQuestion,
  Footer,
} from "../../components";

import { chatMessageVariants } from "../../utilities/Config";

import "./landing.scss";

const LandingPage = () => {
  const carouselRef = useRef();
  const { scrollYProgress } = useScroll();

  const opacity = useTransform(scrollYProgress, [0, 0.01], [1, 0]);
  const translateY = useTransform(scrollYProgress, [0, 0.01], [0, 700]);

  useEffect(() => {
    console.log(
      document.getElementById("section-sequence")?.getBoundingClientRect(),
      "sss"
    );

    /*document.body.classList.add('overflow')*/
    setTimeout(() => {
      /*document.body.classList.remove('overflow')*/
      document.getElementById("section-sequence").classList.add("scale");
      //document.getElementsByClassName('section-phone')[0].classList.add('transform')
    }, 7000);
  });

  return (
    <>
      <Header />
      <motion.div
        id="section-sequence"
        ref={carouselRef}
        className="section-sequence"
        style={{ top: translateY, opacity: opacity }}
      >
        <iframe
          title="animation"
          className="section-sequence__cards"
          src="https://customer-1sq8sjd3ajkgiuwc.cloudflarestream.com/5e2e7bd714fe4e573e845353c7d7a1cf/iframe?muted=true&autoplay=true&poster=https%3A%2F%2Fcustomer-1sq8sjd3ajkgiuwc.cloudflarestream.com%2F5e2e7bd714fe4e573e845353c7d7a1cf%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false"
        ></iframe>
      </motion.div>
      {/*<section className="section-banner">
                <motion.figure variants={heroCardVariants}
                               initial='hiddenLeft'
                               animate='visible'
                               className="hero-card-left">
                    <img src="images/hero-card-left.png" alt="Papel Card"/>
                </motion.figure>
                <motion.figure variants={heroCardVariants}
                               initial='hiddenRight'
                               animate='visible'
                               className="hero-card-right">
                    <img src="images/hero-card-right.png" alt="Papel Card"/>
                </motion.figure>
                <div className="container">
                    <Row gutter={24}>
                        <Col span={24}>
                            <motion.h1 variants={textVariants}
                                       initial='hidden'
                                       animate='visible'
                                       className="text--h1">Papel, a new way of <span className="text--gradient-pink">money</span> management
                            </motion.h1>
                        </Col>
                    </Row>
                </div>
            </section>*/}
      {/*<section className="section-second">
                <div className="container">
                    <Row gutter={24}>
                        <Col span={24}>
                            <motion.h2 variants={textVariants}
                                       initial='hidden'
                                       whileInView="visible"
                                       viewport={{once: true, amount: .1}}
                                       className="text--h1">Papel for <span className="text--gradient-pink">you.</span>
                            </motion.h2>
                        </Col>
                    </Row>
                </div>
            </section>*/}
        <div className="section-phone">
          <div className="container">
            <Scene
              triggerHook="onCenter"
              classToggle="scroll-magic"
              duration={5000}
              pin
            >
              {(progress) => (
                <div className="sticky">
                  <Timeline
                    wrapper={<div className="pinContainer" />}
                    totalProgress={progress}
                    paused
                  >
                    <Tween from={{ x: 0 }} to={{ height: 0, opacity: 0 }}>
                      <div className="test">
                        <div className="grid transfer">
                          <div className="section-phone__description">
                            <Timeline
                              target={<h3 className="text--h3">Para Gönder</h3>}
                            >
                              <Tween
                                from={{ opacity: 0, x: "-200px" }}
                                to={{ opacity: 1, x: "0" }}
                              />
                            </Timeline>
                            <Timeline
                              target={
                                <p className="text--p">
                                  Kolayca ve güvenli bir şekilde para transferi
                                  yapabilirsin.
                                </p>
                              }
                            >
                              <Tween
                                from={{ opacity: 0, x: "-200px" }}
                                to={{ opacity: 1, x: "0" }}
                              />
                            </Timeline>
                          </div>
                          <div className="phone--transfer">
                            <Timeline>
                              <Tween
                                from={{ opacity: 0, y: "20px" }}
                                to={{ opacity: 1, y: "0" }}
                              >
                                <div>
                                  <MessageBox
                                    id="test-1"
                                    className="first"
                                    left={
                                      <div className="message-box__icon">
                                        <i className="icon-user"></i>
                                      </div>
                                    }
                                    middle={
                                      <span className="message-box__text">
                                        Başka Biri
                                      </span>
                                    }
                                    right={<i className="icon-add"></i>}
                                  />
                                </div>
                              </Tween>
                            </Timeline>
                            <Timeline>
                              <Tween
                                from={{ opacity: 0, y: "20px" }}
                                to={{ opacity: 1, y: "0" }}
                              >
                                <div>
                                  <MessageBox
                                    className="second"
                                    size="small"
                                    left={
                                      <div className="message-box__icon--circle">
                                        <i className="icon-arrow-right-up-line"></i>
                                      </div>
                                    }
                                    middle={
                                      <>
                                        <span className="message-box__text">
                                          Onur Gür
                                        </span>
                                        <small>
                                          Received <small>∙</small>
                                          <small>28 Oct</small>
                                        </small>
                                      </>
                                    }
                                    right={
                                      <span className="message-box__text">
                                        - $150.00
                                      </span>
                                    }
                                  />
                                </div>
                              </Tween>
                            </Timeline>
                            <Timeline
                              target={
                                <div className="accessory-1">
                                  <i className="icon-arrow-right-up-line"></i>
                                </div>
                              }
                            >
                              <Tween
                                from={{ opacity: 0, x: "0", y: "" }}
                                to={{ opacity: 1, x: "150px", y: "-200px" }}
                              />
                            </Timeline>
                            <Timeline
                              target={
                                <figure className="accessory-2">
                                  <img
                                    src="images/icon-2.png"
                                    srcSet="images/icon-2-2x.png 2x, images/icon-2.png 1x"
                                    alt="Avatar"
                                  />
                                </figure>
                              }
                            >
                              <Tween
                                from={{ opacity: 0 }}
                                to={{ opacity: 1 }}
                              />
                            </Timeline>
                            <div className="chat-messages-wrapper">
                              <Timeline>
                                <Tween
                                  from={{ opacity: 0, y: "20px" }}
                                  to={{ opacity: 1, y: "0" }}
                                >
                                  <div>
                                    <ChatMessage
                                      avatar={
                                        <img
                                          src="images/icon-1.png"
                                          srcSet="images/icon-1-2x.png 2x, images/icon-1.png 1x"
                                          alt="Avatar"
                                        />
                                      }
                                      messages={[
                                        "How much money do you need😐",
                                        "I'm sending",
                                      ]}
                                    />
                                  </div>
                                </Tween>
                              </Timeline>
                              <Timeline>
                                <Tween
                                  from={{ opacity: 0, y: "20px" }}
                                  to={{ opacity: 1, y: "0" }}
                                >
                                  <div>
                                    <ChatMessage
                                      type="yellow"
                                      recipient={true}
                                      messages={["$150.00", "🤪🤑🥰😍😘🥲"]}
                                    />
                                  </div>
                                </Tween>
                              </Timeline>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tween>
                  </Timeline>
                </div>
              )}
            </Scene>
            <Scene
              triggerHook="onCenter"
              classToggle="scroll-magic"
              duration={5000}
              pin
            >
              {(progress) => (
                <div className="sticky">
                  <Timeline
                    wrapper={<div className="pinContainer" />}
                    totalProgress={progress}
                    paused
                  >
                    <Tween to={{ height: 0, opacity: 0 }}>
                      <div className="test">
                        <div className="grid bill">
                          <div className="section-phone__description">
                            <Timeline
                              target={
                                <h3 className="text--h3">Harcama Bölüştür</h3>
                              }
                            >
                              <Tween
                                from={{ opacity: 0, x: "-200px" }}
                                to={{ opacity: 1, x: "0" }}
                              />
                            </Timeline>
                            <Timeline
                              target={
                                <p className="text--p">
                                  Harcamalarını arkadaşlarınla kolayca bölüştür.
                                </p>
                              }
                            >
                              <Tween
                                from={{ opacity: 0, x: "-200px" }}
                                to={{ opacity: 1, x: "0" }}
                              />
                            </Timeline>
                          </div>
                          <div className="phone--bill">
                            <Timeline>
                              <Tween
                                from={{ opacity: 0, y: "20px" }}
                                to={{ opacity: 1, y: "0" }}
                              >
                                <div>
                                  <MessageBox
                                    className="bill"
                                    type="only"
                                    middle={
                                      <>
                                        <div>
                                          <UserPortrait />
                                          <UserPortrait />
                                        </div>
                                        <Button
                                          center
                                          size="big"
                                          iconLeft={
                                            <i className="icon-add"></i>
                                          }
                                        >
                                          Create Split Bill
                                        </Button>
                                      </>
                                    }
                                  />
                                </div>
                              </Tween>
                            </Timeline>
                            <Timeline
                              target={
                                <figure className="accessory-3">
                                  <img
                                    src="images/icon-3.png"
                                    srcSet="images/icon-3-2x.png 2x, images/icon-3.png 1x"
                                    alt="Avatar"
                                  />
                                </figure>
                              }
                            >
                              <Tween
                                from={{ opacity: 0 }}
                                to={{ opacity: 1 }}
                              />
                            </Timeline>

                            <div className="accessory-4">
                              <Timeline
                                target={
                                  <span>
                                    - $60.00
                                    <Triangle />
                                  </span>
                                }
                              >
                                <Tween
                                  from={{ opacity: 0 }}
                                  to={{ opacity: 1 }}
                                />
                              </Timeline>
                              <Timeline
                                target={
                                  <figure>
                                    <img
                                      src="images/icon-4.png"
                                      srcSet="images/icon-4-2x.png 2x, images/icon-4.png 1x"
                                      alt="Avatar"
                                    />
                                  </figure>
                                }
                              >
                                <Tween
                                  from={{ opacity: 0 }}
                                  to={{ opacity: 1 }}
                                />
                              </Timeline>
                            </div>
                            <div className="chat-messages-wrapper">
                              <Timeline>
                                <Tween
                                  from={{ opacity: 0, y: "20px" }}
                                  to={{ opacity: 1, y: "0" }}
                                >
                                  <div>
                                    <ChatMessage
                                      variants={chatMessageVariants}
                                      size="small"
                                      recipient
                                      type="green"
                                      messages={[
                                        "I'm paying now",
                                        "wait to pay",
                                      ]}
                                    />
                                  </div>
                                </Tween>
                              </Timeline>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tween>
                  </Timeline>
                </div>
              )}
            </Scene>
            <Scene
              triggerHook="onCenter"
              classToggle="scroll-magic"
              duration={5000}
              pin
            >
              {(progress) => (
                <div className="sticky">
                  <Timeline
                    wrapper={<div className="pinContainer" />}
                    totalProgress={progress}
                    paused
                  >
                    <Tween to={{ height: 0, opacity: 0 }}>
                      <div className="test">
                        <div className="grid invoices">
                          <div className="section-phone__description">
                            <Timeline
                              target={
                                <h3 className="text--h3">Fature Ödeme</h3>
                              }
                            >
                              <Tween
                                from={{ opacity: 0, x: "-200px" }}
                                to={{ opacity: 1, x: "0" }}
                              />
                            </Timeline>
                            <Timeline
                              target={
                                <p className="text--p">
                                  Faturalarınızı ve ödemelerinizi güvenli ve anında yapabilirsiniz. Bu özellik sayesinde finansal bilgilerinizin güvenliğinden endişe duymadan faturanızın detaylarını girebilir ve kolayca ödeme yapabilirsiniz.
                                </p>
                              }
                            >
                              <Tween
                                from={{ opacity: 0, x: "-200px" }}
                                to={{ opacity: 1, x: "0" }}
                              />
                            </Timeline>
                          </div>
                          <div className="phone--invoices">
                            <Timeline>
                              <Tween
                                from={{ opacity: 0, y: "20px" }}
                                to={{ opacity: 1, y: "0" }}
                              >
                                <div>
                                  <MessageBox
                                    className="invoices"
                                    type="only"
                                    middle={
                                      <>
                                        <ActionMenu
                                          icon="electric"
                                          passive
                                          text="My Electricity Bill"
                                          type="green"
                                        />
                                        <ActionMenu
                                          icon="fire"
                                          text="My Gas Bill"
                                          type="pink"
                                        />
                                        <ActionMenu
                                          icon="water"
                                          passive
                                          text="My Water Bill"
                                          type="aqua"
                                        />
                                      </>
                                    }
                                  />
                                </div>
                              </Tween>
                            </Timeline>
                            <Timeline
                              target={
                                <div className="accessory-5">
                                  <IconCircle
                                    size="big"
                                    icon="monitor"
                                    type="yellow"
                                  />
                                </div>
                              }
                            >
                              <Tween
                                from={{ opacity: 0 }}
                                to={{ opacity: 1 }}
                              />
                            </Timeline>
                            <div className="chat-messages-wrapper">
                              <Timeline>
                                <Tween
                                  from={{ opacity: 0, y: "20px" }}
                                  to={{ opacity: 1, y: "0" }}
                                >
                                  <div>
                                    <ChatMessage
                                      size="small"
                                      avatar={
                                        <img
                                          src="images/avatar-invoices.png"
                                          srcSet="images/avatar-invoices-2x.png 2x, images/avatar-invoices.png 1x"
                                          alt="Avatar"
                                        />
                                      }
                                      type="purple"
                                      messages={["Did you pay the gas bill?"]}
                                    />
                                  </div>
                                </Tween>
                              </Timeline>
                              <Timeline>
                                <Tween
                                  from={{ opacity: 0, y: "20px" }}
                                  to={{ opacity: 1, y: "0" }}
                                >
                                  <div>
                                    <ChatMessage
                                      size="small"
                                      avatar={
                                        <img
                                          src="images/avatar-invoices-2.png"
                                          srcSet="images/avatar-invoices-2-2x.png 2x, images/avatar-invoices-2.png 1x"
                                          alt="Avatar"
                                        />
                                      }
                                      type="pink"
                                      messages={[
                                        "Didn't your father pay the bill?",
                                      ]}
                                    />
                                  </div>
                                </Tween>
                              </Timeline>
                            </div>
                            <div className="accessory-6">
                              <Timeline
                                target={
                                  <div>
                                    <IconCircle icon="security" type="green" />
                                  </div>
                                }
                              >
                                <Tween
                                  from={{ opacity: 0 }}
                                  to={{ opacity: 1 }}
                                />
                              </Timeline>
                              <Timeline
                                target={
                                  <div>
                                    <IconCircle icon="gift" type="pink" />
                                  </div>
                                }
                              >
                                <Tween
                                  from={{ opacity: 0 }}
                                  to={{ opacity: 1 }}
                                />
                              </Timeline>
                              <Timeline
                                target={
                                  <div>
                                    <IconCircle icon="hand-heart" type="aqua" />
                                  </div>
                                }
                              >
                                <Tween
                                  from={{ opacity: 0 }}
                                  to={{ opacity: 1 }}
                                />
                              </Timeline>
                              <Timeline
                                target={
                                  <div>
                                    <IconCircle icon="joystick" type="yellow" />
                                  </div>
                                }
                              >
                                <Tween
                                  from={{ opacity: 0 }}
                                  to={{ opacity: 1 }}
                                />
                              </Timeline>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tween>
                  </Timeline>
                </div>
              )}
            </Scene>
            <Scene
              triggerHook="onCenter"
              classToggle="scroll-magic"
              duration={5000}
              pin
            >
              {(progress) => (
                <div className="sticky">
                  <Timeline
                    wrapper={<div className="pinContainer" />}
                    totalProgress={progress}
                    paused
                  >
                    <Tween to={{ height: 0, opacity: 0 }}>
                      <div className="test">
                        <div className="grid start">
                          <div className="section-phone__description">
                            <Timeline
                              target={<h3 className="text--h3">Hemen Başla</h3>}
                            >
                              <Tween
                                from={{ opacity: 0, x: "-200px" }}
                                to={{ opacity: 1, x: "0" }}
                              />
                            </Timeline>
                            <Timeline
                              target={
                                <p className="text--p">
                                  Güvenli, basit ve kolay bir ödeme yöntemi sunmaktan mutluluk duyuyoruz.
                                </p>
                              }
                            >
                              <Tween
                                from={{ opacity: 0, x: "-200px" }}
                                to={{ opacity: 1, x: "0" }}
                              />
                            </Timeline>
                          </div>
                          <div className="phone--start">
                            <Timeline>
                              <Tween
                                from={{ opacity: 0, y: "20px" }}
                                to={{ opacity: 1, y: "0" }}
                              >
                                <div>
                                  <MessageBox
                                    className="start"
                                    left={
                                      <div className="message-box__icon">
                                        <i className="icon-papel-logo"></i>
                                      </div>
                                    }
                                    middle={
                                      <div className="block">
                                        <span className="message-box__text">
                                          Papel bireysel
                                        </span>
                                        <small>
                                          Tüm finansal ihtiyaçların için tek bir
                                          uygulama.
                                        </small>
                                      </div>
                                    }
                                    right={<i className="icon-cloud"></i>}
                                  />
                                </div>
                              </Tween>
                            </Timeline>
                            <div className="chat-messages-wrapper">
                              <Timeline>
                                <Tween
                                  from={{ opacity: 0, y: "20px" }}
                                  to={{ opacity: 1, y: "0" }}
                                >
                                  <div>
                                    <ChatMessage
                                      size="small"
                                      type="pink"
                                      recipient
                                      messages={["download the app"]}
                                    />
                                  </div>
                                </Tween>
                              </Timeline>
                              <Timeline>
                                <Tween
                                  from={{ opacity: 0, y: "20px" }}
                                  to={{ opacity: 1, y: "0" }}
                                >
                                  <div>
                                    <ChatMessage
                                      size="small"
                                      type="green"
                                      recipient
                                      messages={["I already downloaded"]}
                                    />
                                  </div>
                                </Tween>
                              </Timeline>
                              <Timeline>
                                <Tween
                                  from={{ opacity: 0, y: "20px" }}
                                  to={{ opacity: 1, y: "0" }}
                                >
                                  <div>
                                    <ChatMessage
                                      size="small"
                                      recipient
                                      messages={["😝😝😝😝"]}
                                    />
                                  </div>
                                </Tween>
                              </Timeline>
                              <Timeline>
                                <Tween
                                  from={{ opacity: 0, y: "20px" }}
                                  to={{ opacity: 1, y: "0" }}
                                >
                                  <div>
                                    <ChatMessage
                                      size="small"
                                      type="green"
                                      recipient
                                      messages={["did you do your homework"]}
                                    />
                                  </div>
                                </Tween>
                              </Timeline>
                              <Timeline>
                                <Tween
                                  from={{ opacity: 0, y: "20px" }}
                                  to={{ opacity: 1, y: "0" }}
                                >
                                  <div>
                                    <ChatMessage
                                      size="small"
                                      recipient
                                      messages={["🤫🥱🥱😴😴😴"]}
                                    />
                                  </div>
                                </Tween>
                              </Timeline>
                              <Timeline>
                                <Tween
                                  from={{ opacity: 0, y: "20px" }}
                                  to={{ opacity: 1, y: "0" }}
                                >
                                  <div>
                                    <ChatMessage
                                      size="small"
                                      type="green"
                                      recipient
                                      messages={[
                                        "sister can you send money🫣🤔",
                                      ]}
                                    />
                                  </div>
                                </Tween>
                              </Timeline>
                            </div>
                          </div>
                          <Timeline
                            target={
                              <figure className="accessory-7">
                                <img
                                  src="images/avatar-start.png"
                                  srcSet="images/avatar-start-2x.png 2x, images/avatar-start.png 1x"
                                  alt="Papel Card"
                                />
                              </figure>
                            }
                          >
                            <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} />
                          </Timeline>
                          <Timeline
                            target={
                              <figure className="accessory-8">
                                <img
                                  src="images/avatar-start-2.png"
                                  srcSet="images/avatar-start-2-2x.png 2x, images/avatar-start-2.png 1x"
                                  alt="Papel Card"
                                />
                              </figure>
                            }
                          >
                            <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} />
                          </Timeline>
                          <Timeline
                            target={
                              <figure className="accessory-9">
                                <img
                                  src="images/avatar-start-3.png"
                                  srcSet="images/avatar-start-3-2x.png 2x, images/avatar-start-3.png 1x"
                                  alt="Papel Card"
                                />
                              </figure>
                            }
                          >
                            <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} />
                          </Timeline>
                          <Timeline
                            target={
                              <figure className="accessory-10">
                                <img
                                  src="images/avatar-start-4.png"
                                  srcSet="images/avatar-start-4-2x.png 2x, images/avatar-start-4.png 1x"
                                  alt="Papel Card"
                                />
                              </figure>
                            }
                          >
                            <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} />
                          </Timeline>
                        </div>
                      </div>
                    </Tween>
                  </Timeline>
                </div>
              )}
            </Scene>
          </div>
        </div>

      <SectionBusiness />
      <SectionLoyalty />
      <SectionFinancial />
      <SectionQuestion />
      <SectionContact />
      <Footer />
    </>
  );
};

export default LandingPage;
