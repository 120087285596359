import { ProductBox } from "../../index";
import { Row, Col } from "antd";
import { motion } from "framer-motion";
import { Scene } from "react-scrollmagic";
import { Timeline, Tween } from "react-gsap";
import {
  imagesVariants,
  phoneTextEvenVariants,
} from "../../../utilities/Config";

const Loyalty = () => {
  return (
    <section className="section-loyalty">
      <div className="container">
        <Scene
          triggerHook="onLeave"
          classToggle="scroll-magic"
          duration={5000}
          pin
        >
          {(progress) => (
            <div className="sticky">
              <iframe
                title="animation"
                className="section-loyalty__face"
                src="https://customer-1sq8sjd3ajkgiuwc.cloudflarestream.com/5095a08579f87ba075983fb35f6eea14/iframe?muted=true&loop=true&autoplay=true&poster=https%3A%2F%2Fcustomer-1sq8sjd3ajkgiuwc.cloudflarestream.com%2F5095a08579f87ba075983fb35f6eea14%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
              ></iframe>
              <Timeline
                wrapper={<div className="pinContainer2" />}
                totalProgress={progress}
                paused
              >
                <Tween>
                  <div className="test">
                    <div>
                      <div className="section-loyalty__head">
                        <Timeline>
                          <Tween
                            from={{ opacity: 0, x: "400px" }}
                            to={{ opacity: 1, x: "20px" }}
                          >
                            <h3 className="text--h3">Sadakat</h3>
                          </Tween>
                        </Timeline>
                        <Timeline>
                          <Tween
                            from={{ opacity: 0, x: "-400px" }}
                            to={{ opacity: 1, x: "20px" }}
                          >
                            <p className="text--p">
                              Özel indirimler, promosyonlar ve etkinlikler için
                              sadakat programımıza katılın. Bu avantajları
                              kaçırmayın!
                            </p>
                          </Tween>
                        </Timeline>
                      </div>
                    </div>
                  </div>
                </Tween>
              </Timeline>
            </div>
          )}
        </Scene>

        <Scene
          triggerHook="onLeaveBack"
          classToggle="scroll-magic"
          duration={5000}
          pin
        >
          {(progress) => (
            <div className="sticky">
              <Timeline
                wrapper={<div className="pinContainer" />}
                totalProgress={progress}
                paused
              >
                <Tween>
                  <div className="test">
                    <div>
                      <Row className="section-loyalty__text" gutter={24}>
                        <Col md={8} span={24} className="grid-left">
                          <Timeline
                            target={
                              <h3 className="text--h4">
                                Üst düzey kontrol için Sadakat programını tercih
                                edin
                              </h3>
                            }
                          >
                            <Tween
                              from={{ opacity: 0, x: "-200px", y: "600px" }}
                              to={{ opacity: 1, x: "0", y: "20px" }}
                            />
                          </Timeline>
                        </Col>
                        <Col
                          md={{ span: 8, offset: 8 }}
                          span={24}
                          className="grid-left"
                        >
                          <Timeline
                            target={
                              <p className="text--subtitle">
                                Sadakat programımız müşterilerimize özel
                                avantajlar sunuyor.
                              </p>
                            }
                          >
                            <Tween
                              from={{ opacity: 0, x: "300px" }}
                              to={{ opacity: 1, x: "20px" }}
                            />
                          </Timeline>
                        </Col>
                      </Row>
                      <Row gutter={[24, 75]}>
                        <Col lg={{ span: 10, offset: 6 }} md={12} span={24}>
                          <Timeline>
                            <Tween
                              from={{ opacity: 0, y: "100px" }}
                              to={{ opacity: 1, y: "-20px" }}
                            >
                              <div>
                                <ProductBox
                                  type="Tüm Abonelikler"
                                  className="product-box--cashback"
                                  title="Cashback"
                                  image={
                                    <motion.figure variants={imagesVariants}>
                                      <img
                                        src="images/product-cashback.png"
                                        srcSet="images/product-cashback-2x.png 2x, images/product-cashback.png 1x"
                                        alt="product cashback"
                                      />
                                    </motion.figure>
                                  }
                                  icons={[]}
                                />
                              </div>
                            </Tween>
                          </Timeline>
                        </Col>
                        <Col lg={8} md={12} span={24}>
                          <Timeline>
                            <Tween
                              from={{ opacity: 0, y: "100px" }}
                              to={{ opacity: 1, y: "-20px" }}
                            >
                              <div>
                                <ProductBox
                                  compType="v2"
                                  type="Gold & Metal Kartlar"
                                  className="product-box--exclusive"
                                  title="Ayrıcalıklı Hizmetler"
                                  image={
                                    <motion.figure variants={imagesVariants}>
                                      <img
                                        src="images/product-exclusive.png"
                                        srcSet="images/product-exclusive-2x.png 2x, images/product-exclusive.png 1x"
                                        alt="product exclusive"
                                      />
                                    </motion.figure>
                                  }
                                  icons={[]}
                                />
                              </div>
                            </Tween>
                          </Timeline>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tween>
              </Timeline>
            </div>
          )}
        </Scene>
        <Scene
          triggerHook="onLeave"
          classToggle="scroll-magic"
          duration={5000}
          pin
        >
          {(progress) => (
            <div className="sticky">
              <Timeline
                wrapper={<div className="pinContainer2" />}
                totalProgress={progress}
                paused
              >
                <Tween>
                  <div className="test">
                    <div>
                      <Row gutter={[24, 75]}>
                        <Col lg={8} md={12} span={24}>
                          <Timeline>
                            <Tween
                              from={{ opacity: 0, y: "400px" }}
                              to={{ opacity: 1, y: "20px" }}
                            >
                              <div>
                                <ProductBox
                                  boxType="pink-v2"
                                  compType="v2"
                                  type="Gold & Metal Kartlar"
                                  title="Sizin için özel asistan"
                                  icons={[]}
                                />
                              </div>
                            </Tween>
                          </Timeline>
                        </Col>
                        <Col lg={10} md={12} span={24}>
                          <Timeline>
                            <Tween
                              from={{ opacity: 0, y: "400px" }}
                              to={{ opacity: 1, y: "20px" }}
                            >
                              <div>
                                <ProductBox
                                  boxType="custom"
                                  type="Gold & Metal Kartlar"
                                  className="product-box--transactions"
                                  title={
                                    <span className="text--gradient-purple">
                                      Ücretsiz İşlemler
                                    </span>
                                  }
                                  image={
                                    <motion.figure
                                      variants={phoneTextEvenVariants}
                                    >
                                      <img
                                        src="images/product-transactions.png"
                                        srcSet="images/product-transactions-2x.png 2x, images/product-transactions.png 1x"
                                        alt="product transactions"
                                      />
                                    </motion.figure>
                                  }
                                  icons={[]}
                                />
                              </div>
                            </Tween>
                          </Timeline>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tween>
              </Timeline>
            </div>
          )}
        </Scene>
      </div>
    </section>
  );
};

export default Loyalty;
