import PropTypes from "prop-types";
import classNames from "classnames";
import {motion} from "framer-motion";
import {phoneTextVariants, productBoxContainerVariants} from "../../utilities/Config";


const ProductBox = ({
                        type = 'Akıllı Terminal',
                        boxType,
                        image,
                        title = 'Ödeme',
                        icons = ['bank-card', 'qr-code', 'wifi'],
                        horizontal,
                        vertical,
                        className,
                        children,
                        compType,
                        optType,
                        ...props
                    }) => {
    return (
        <motion.div variants={productBoxContainerVariants}
                    initial="hidden"
                    viewport={{amount: .1}}
                    whileInView="whileInView"
                    className={classNames('product-box', compType && `product-box--${compType}`, className,
                        {
                            'product-box--dark': boxType === 'dark',
                            'product-box--pink': boxType === 'pink',
                            'product-box--pink-v2': boxType === 'pink-v2',
                            'product-box--custom': boxType === 'custom',
                            'product-box--horizontal': horizontal,
                            'product-box--vertical': vertical,
                        }
                    )} {...props}>
            {type && <motion.div variants={phoneTextVariants}
                                 className="product-box__type text--subtitle">{type}</motion.div>}
            <motion.div variants={phoneTextVariants} className="product-box__title text--h3">{title}</motion.div>
            {optType && optType}
            {image && image}
            {icons.length > 0 && <div className="product-box__icons">
                {icons.map((item, key) => <motion.i variants={phoneTextVariants} className={`icon-${item}`}
                                                    key={`product-box-icon-${key}`}></motion.i>)}
            </div>}
            {children}
        </motion.div>
    )
}

ProductBox.propTypes = {
    type: PropTypes.any,
    children: PropTypes.any,
    boxType: PropTypes.string,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.element,
    ]),
    compType: PropTypes.string,
    icons: PropTypes.array,
    image: PropTypes.element,
    horizontal: PropTypes.bool
}

export default ProductBox;