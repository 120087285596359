import React from "react";
import { Row, Col } from "antd";
import { Collapse } from "../../index";
import { Questions } from "../../../utilities/DummyContent";
import { Controller, Scene } from "react-scrollmagic";
import { Timeline, Tween } from "react-gsap";

const SectionQuestion = () => {
  return (
    <section className="section-questions">
      <div className="container">
   
          <Scene
            triggerHook="onLeave"
            classToggle="scroll-magic"
            duration={5000}
            pin
          >
            {(progress) => (
              <div className="sticky">
                <Timeline
                  wrapper={<div className="pinContainer2" />}
                  totalProgress={progress}
                  paused
                >
                  <Tween>
                    <div className="test">
                      <div>
                        <Row gutter={24}>
                          <Col lg={10} span={24}>
                            <Timeline>
                              <Tween
                                from={{ opacity: 0.3, x: "-200px", y: '300px' }}
                                to={{ opacity: 1, x: "0", y: '40px'  }}
                              >
                                <h4 className="text--h4 text--white">
                                  Sıkça Sorulan Sorular
                                </h4>
                              </Tween>
                            </Timeline>
                          </Col>
                          <Col lg={14} span={24}>
                            <Timeline>
                              <Tween
                                from={{ opacity: 0, x: "200px", y: "40px"}}
                                to={{ opacity: 1, x: "0"}}
                              >
                                <div className="questions-wrapper">
                                  <Collapse data={Questions} />
                                </div>
                              </Tween>
                            </Timeline>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Tween>
                </Timeline>
              </div>
            )}
          </Scene>

      </div>
    </section>
  );
};

export default SectionQuestion;
