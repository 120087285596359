import {Row, Col} from "antd";
import {ProductBox} from "../../index";
import {Timeline, Tween} from "react-gsap";
import {Controller, Scene} from "react-scrollmagic";

const SectionFinancial = () => {
    return (
        <section className="section-financial">
            <div className="container">
                
                    <Scene
                        triggerHook="onLeaveBack"
                        classToggle="scroll-magic"
                        duration={5000}
                        pin
                    >
                        {(progress) => (
                            <div className="sticky">
                                <Timeline
                                    wrapper={<div className="pinContainer"/>}
                                    totalProgress={progress}
                                    paused
                                >
                                    <Tween>
                                        <div className="test">
                                            <div>
                                                <Timeline
                                                    target={
                                                        <h3 className="text--h3 text--dark section__title">
                                                            Güvenli ve hızlı finansal çözümler için <span className="text--gradient-v4"> Papel! </span>
                                                        </h3>
                                                    }
                                                >
                                                    <Tween
                                                        from={{opacity: 0, x: "-200px"}}
                                                        to={{opacity: 1, x: "0"}}
                                                    />
                                                </Timeline>
                                                <Timeline
                                                    target={
                                                        <p className="text--h5 text--dark">
Güvenliğin önemini biliyoruz. Uygulamalarımızı kullandığınızda kişisel bilgilerinizi ve mali durumunuzu korumaya öncelik veriyoruz. Güvenliğinizin bizim en büyük önceliğimizdir.
                                                        </p>
                                                    }
                                                >
                                                    <Tween
                                                        from={{opacity: 0, x: "200px"}}
                                                        to={{opacity: 1, x: "0"}}
                                                    />
                                                </Timeline>
                                            </div>
                                        </div>
                                    </Tween>
                                </Timeline>
                            </div>
                        )}
                    </Scene>
                    <Scene
                        triggerHook="onLeave"
                        classToggle="scroll-magic"
                        duration={5000}
                        pin>
                        {(progress) => (
                            <div>
                                <Timeline wrapper={<div className="pinContainer2"/>} totalProgress={progress} paused>
                                    <Tween>
                                        <div className="test">
                                            <div>
                                                <Row gutter={[24, {lg: 96, md: 24, sm: 24, xs: 24}]}>
                                                    <Col md={12}>
                                                        <Timeline>
                                                            <Tween
                                                                from={{opacity: 0, x: '-200px', y: '20px'}}
                                                                to={{opacity: 1, x: '0', y: '20px'}}
                                                            >
                                                                <div>
                                                                    <ProductBox type={false}
                                                                                icons={[]}
                                                                                className="product-box--fast"
                                                                                image={<figure><img
                                                                                    src="images/product-fast.png"
                                                                                    srcSet="images/product-fast-2x.png 2x, images/product-fast.png 1x"
                                                                                    alt="product fast"/></figure>}
                                                                                title={<>Her zaman hızlı ve <span
                                                                                    className="text--gradient-v4">güvenilir hizmet</span>
                                                                                    
                                                                                    
                                                                                    </>}>
                                                                    </ProductBox>
                                                                </div>
                                                            </Tween>
                                                        </Timeline>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Timeline>
                                                            <Tween
                                                                from={{opacity: 0, x: '100px', y: '20px'}}
                                                                to={{opacity: 1, x: '0', y: '20px'}}
                                                            >
                                                                <div>
                                                                    <ProductBox type={false}
                                                                                boxType="custom"
                                                                                icons={[]}
                                                                                className="product-box--secure"
                                                                                image={<figure><img
                                                                                    src="images/product-secure.png"
                                                                                    srcSet="images/product-secure-2x.png 2x, images/product-secure.png 1x"
                                                                                    alt="product secure"/></figure>}
                                                                                title={<> Her adımda bizimle  <span
                                                                                    className="text--gradient-v4">güvendesiniz</span></>}>
                                                                    </ProductBox>
                                                                </div>
                                                            </Tween>
                                                        </Timeline>

                                                    </Col>
                                                </Row>

                                            </div>
                                        </div>
                                    </Tween>
                                </Timeline>

                            </div>
                        )}
                    </Scene>
                    <Scene
                        classToggle="scroll-magic"
                        duration={5000}
                    >
                        {progress => (
                            <div className="sticky">
                                <Timeline wrapper={<div className="pinContainer2"/>} totalProgress={progress} paused>
                                    <Tween>
                                        <div className="test">
                                            <div>
                                                <Col md={24}>
                                                    <Timeline>
                                                        <Tween
                                                            from={{y: '60px'}}
                                                        >
                                                            <div className="product-box--pricing">
                                                                <div className="product-box__left">
                                                                    <div className="text--h3 text--dark">Size en uygun ücretler.
                                                                    </div>
                                                                </div>
                                                                <div className="product-box__right">
                                                                    <div>
                                                                        <div className="text--h6 text--dark">Ücretsiz olarak başlayın
                                                                        </div>
                                                                        <p className="text--caption text--dark">30 gün boyunca ücretsiz olarak deneyin.</p>
                                                                    </div>
                                                                    <div>
                                                                        <div className="text--h6 text--dark">Tüm kartlar için aynı ücreti ödeyin
                                                                        </div>
                                                                        <p className="text--caption text--dark">Her tür ödeme için net işlem ücretleri.</p>
                                                                    </div>
                                                                    <div>
                                                                        <div className="text--h6 text--dark">Gizli ücretler veya uzun vadeli sözleşmeler yok
                                                                        </div>
                                                                        <p className="text--caption text--dark">Başlangıç ücretleri, yetkilendirme ücretleri, hesap özeti ücretleri, PCI uyumluluk ücretleri ve daha fazlasını ödemeden hizmetimizden yararlanın.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tween>
                                                    </Timeline>
                                                </Col>

                                            </div>
                                        </div>
                                    </Tween>
                                </Timeline>


                            </div>
                        )}
                    </Scene>


            </div>
        </section>
    );
};

export default SectionFinancial;
