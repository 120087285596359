import { Col, Row } from "antd";
import React from "react";
import {
  Footer,
  Header,
  ProductBox,
  SectionContact,
  SectionFlights,
  SectionQuestion,
} from "../../components";
import "./gold-card.scss";
import { motion } from "framer-motion";
import {
  textVariants,
  phoneTextVariants,
  phoneTextEvenVariants,
  globalVariants,
  imagesVariants,

} from "../../utilities/Config";

const GoldCard = () => {
  return (
    <>
      <Header />
      <motion.section
            variants={globalVariants}
                      initial="hidden"
                      viewport={{ once: true, amount: 0.1 }}
                      whileInView="whileInView"
      className="section-different">
        <div className="container">
          <Row>
            <Col className="text-center" lg={16} md={24} sm={24} xs={24}>
              <motion.h6 variants={phoneTextVariants} className="text--decorative-1">PAPEL</motion.h6>
              <motion.h2 variants={textVariants} className="text--h2 text-light">
              Diğerlerinden çok 
                <span className="text--gradient-pink"> farklı</span>
              </motion.h2>
            </Col>
          </Row>
          <Row>
            <Col className="text-center" lg={16} md={16} sm={24} xs={24}>
              <motion.p variants={phoneTextEvenVariants} className="text--p ">
              Mükemmel döviz kurları sayesinde yerelde gibi ödeme yapın ve dünya çapında seyahat kazanın
              </motion.p>
            </Col>
          </Row>
          <motion.figure
          variants={imagesVariants}
          >
            <img
              className="goldcard-bg"
              src="images/goldcard-bg.png"
              srcSet="images/goldcard-bg-2x.png 2x, images/goldcard-bg.png 1x"
              alt="gold card background"
            />
          </motion.figure>
        </div>
      </motion.section>
      <section className="section-gold-card">
        <div className="container">
          <motion.div whileInView="whileInView" initial="hidden"  variants={imagesVariants} viewport={{once: true, amount: .1}} className="gold-card-text text-center">
            <span className="text--lg text--gradient-dark-stroke">GOLD</span>
            <span className="text--lg text--gradient-stroke">KART</span>
          </motion.div>
        </div>
      </section>
      <section className="section-shadow">
        <div className="container">
          <Row>
            <Col className="text-center" md={22}>
              <motion.h4
                            whileInView="whileInView"
                            initial="hidden"
                            variants={phoneTextVariants}
                            viewport={{once: true, amount: .1}}
              className="text--h4">
                <span className="text--gradient-purple">
                Mükemmel döviz kurları sayesinde yerelde gibi ödeme yapın ve dünya çapında seyahat kazanın. Papel kartınızla hiçbir gizli ücret ödemeden 150'den fazla para birimini zahmetsizce harcayın.
                </span>
              </motion.h4>
              <motion.figure
              whileInView="whileInView"
              initial="hidden"
              variants={imagesVariants}
              
              >
                <img
                  className="phone-shadow"
                  src="images/phone-shadow.png"
                  srcSet="images/phone-shadow-2x.png 2x, images/phone-shadow.png 1x"
                  alt="phone shadow"
                />
              </motion.figure>
            </Col>
          </Row>
        </div>
      </section>
      <motion.section className="section-themes">
        <div className="container">
          <Row>
            <Col md={16} className="text-center">
              <motion.h3
                variants={textVariants}
                initial="hidden"
                viewport={{ once: true, amount: 0.1 }}
                whileInView="whileInView"
                className="text--h3 text--dark"
              >
                Kart <span className="text--gradient-purple">Temaları</span>
              </motion.h3>
              <motion.p
                initial="hidden"
                viewport={{ once: true, amount: 0.1 }}
                whileInView="whileInView"
                variants={phoneTextVariants}
                className="text--subtitle text--dark"
              >
                Papel, finansal özgürlüğü kolay yaşamla birleştirerek, dünyanın önde gelen ödeme kartı çıkaran ve lüks yaşam tarzı yönetim grubudur.
              </motion.p>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col md={24}>
              <ProductBox
                type={false}
                optType={
                  <motion.span
                    variants={phoneTextEvenVariants}
                    className="text--caption text--light"
                  >
                    "The Kiss" by Gustav Klimt.
                  </motion.span>
                }
                className="product-box--modern-art"
                title={<h6 className="text--h6 text--white">Modern Snat</h6>}
                image={
                  <motion.figure variants={imagesVariants}>
                    <img
                      src="images/product-modern-art.png"
                      srcSet="images/product-modern-art-2x.png 2x, images/product-modern-art.png 1x"
                      alt="modern art"
                    />
                  </motion.figure>
                }
                icons={[]}
              ></ProductBox>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <ProductBox
                compType="custom"
                type={false}
                optType={
                  <motion.div
                    variants={phoneTextEvenVariants}
                    className="text--caption text--light"
                  >
                    "The Kiss," by Gustav Klimt.
                  </motion.div>
                }
                className="product-box--feminine"
                title={<h6 className="text--h6 text--white">Feminen</h6>}
                image={
                  <motion.figure variants={imagesVariants}>
                    <img
                      src="images/product-feminine.png"
                      srcSet="images/product-feminine-2x.png 2x, images/product-feminine.png 1x"
                      alt="product exclusive"
                    />
                  </motion.figure>
                }
                icons={[]}
              ></ProductBox>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <ProductBox
                compType="custom"
                optType={
                  <motion.span
                    variants={phoneTextEvenVariants}
                    className="text--caption text--light"
                  >
                    Asırlar boyunca, kimono Japon kültürünün temel sembollerinden biri olarak kalmıştır. Hatları özgündür ve desenleri zariftir.
                  </motion.span>
                }
                type={false}
                className="product-box--ancient-art"
                title={<h6 className="text--h6 text--white">Antik Sanat</h6>}
                image={
                  <motion.figure variants={imagesVariants}>
                    <img
                      src="images/product-ancient-art.png"
                      srcSet="images/product-ancient-art-2x.png 2x, images/product-ancient-art.png 1x"
                      alt="product abroad"
                    />
                  </motion.figure>
                }
                icons={[]}
              ></ProductBox>
            </Col>
          </Row>
        </div>
      </motion.section>
      <SectionFlights />
      <SectionQuestion />
      <SectionContact />
      <Footer />
    </>
  );
};

export default GoldCard;
